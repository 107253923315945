import React, { useCallback, useMemo } from "react"
import Headroom from "react-headroom"
import DesktopHeader from "./desktop"
import MobileHeader from "./mobile"
import AnnouncementBar from "./announcement-bar"
import SidebarContent, { Navigation } from "@components/sidebar/content"
import SocialMedia from "@components/sidebar/footerSocial"
import SearchModal from "@components/search/search-modal"
import useHeaderClass from "./helpers"
import { useSidebarStore } from "@src/hooks/useStore"
import useToggleStates from "@src/hooks/useToggleStates"

const appendProductsToGroup = (childs, products, hasProducts) => {
  if (!hasProducts) return childs

  const mappedChilds = childs?.map(child => {
    const {
      internalLink: { _id, _type },
    } = child

    let products = [];

    if (_type === "productGroup") {
      products = products
        ?.filter(product =>
          product?.productGroups?.find(group => group._id === _id)
        )
        ?.slice(0, 3)
    } else if (_id === "productsIndex") {
      products = products?.slice(0, 3)
    }

    return {
      ...child,
      products,
    }
  })

  return mappedChilds
}

const Header = ({ headerData, footerData }) => {
  let { header, products, sidebar, blogPosts, resourcesPosts, groups, search } =
    headerData

  const { setContent, sidebarOpened, setSettings } = useSidebarStore(["setContent", "sidebarOpened", "setSettings"]);

  const { onPin, onUnfix, onUnpin } = useHeaderClass()
  const { toggleAllFunction } = useToggleStates();

  const memoizedHeader = useMemo(() => {
    return header?.map(node => ({
      ...node,
      childNodes: appendProductsToGroup(
        node.childNodes,
        products,
        node.hasProducts
      ),
    }));
  }, [header]);

  const onHamburgerClick = () => {
    setContent(
      <>
        <SidebarContent className={"main-sidebar"}>
          <Navigation nodes={[...sidebar, ...memoizedHeader]} />
        </SidebarContent>
        <SocialMedia footerData={footerData} />
      </>
    );

    setSettings({ showSearchBar: true })

    toggleAllFunction("sidebar", !sidebarOpened);
  }

  return (
    <header>
      <AnnouncementBar />
        <Headroom onPin={onPin} onUnpin={onUnfix} onUnfix={onUnpin}>
          <DesktopHeader data={memoizedHeader} onHamburgerClick={onHamburgerClick} />
          <MobileHeader onHamburgerClick={onHamburgerClick} />

          <SearchModal
            {...{ products, blogPosts, resourcesPosts, groups, search }}
          />
        </Headroom>
    </header>
  )
}

export default Header
